import Item from '@/domain/Item';
import CreateTrackedItemDTO from '@/dtos/CreateNewTrackedItemDTO';
import { normalizeDate } from '@/functions/date';

export default class TrackedItem {
    public id!: number;

    public item!: Item;

    public itemId!: number;

    public barcode!: string;

    public deleted!: boolean;

    public productionPlant?: string;

    public productionDate? : Date;

    constructor(init?: TrackedItem) {
        if (init) {
            this.id = init.id;
            this.item = new Item(init.item);
            this.itemId = init.item.id;
            this.barcode = init.barcode;
            this.deleted = init.deleted;
            this.productionPlant = init.productionPlant;
            this.productionDate = normalizeDate(init.productionDate);
        }
    }

    toCreateTrackedItemDTO(): CreateTrackedItemDTO {
        return new CreateTrackedItemDTO(this);
    }
}
