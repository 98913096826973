import RouteConfigApiService from '@/services/api/RouteConfigApiService';
import LocationRouteConfig from '@/domain/LocationRouteConfig';
import RouteConfigAddRouteDTO from '@/dtos/RouteConfigAddRouteDTO';
import RouteAddItemDTO from '@/dtos/RouteAddItemDTO';
import Location from '@/domain/Location';
import Item from '@/domain/Item';
import { useNotification } from '@/composable/useNotifications';
import RouteConfigDetailsDTO from '@/dtos/RouteConfigDetailsDTO';
import InventoryCategory from '@/domain/InventoryCategory';
import { getTranslation } from '@/services/TranslationService';

export default class RouteConfigService {
    private routeConfigApiService: RouteConfigApiService;

    private notification = useNotification();

    constructor() {
        this.routeConfigApiService = new RouteConfigApiService();
    }

    public async getRouteConfigsFromLocation(locationId: number): Promise<Array<LocationRouteConfig>> {
        const response = await this.routeConfigApiService.getRouteConfigsFromLocation(locationId);
        if (response.success) {
            return response.data;
        }
        return [];
    }

    public async getAllRouteConfigsByLocation(locationId: number): Promise<Array<LocationRouteConfig>> {
        const response = await this.routeConfigApiService.getAllRouteConfigsByLocation(locationId);
        if (response.success) {
            return response.data;
        }
        return [];
    }

    public async addRoute(fromLocationId: number, toLocation: Location): Promise<LocationRouteConfig | null> {
        const dto = new RouteConfigAddRouteDTO();
        dto.FromId = fromLocationId;
        dto.ToId = toLocation.id;
        const response = await this.routeConfigApiService.addRoute(dto);

        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.addedRoute'));
            return new LocationRouteConfig(response.data);
        }
        return null;
    }

    public async getAllShipFromInventoryCategoriesInRouteConfig(fromLocationId: number, toLocationId: number): Promise<Array<InventoryCategory>> {
        const response = await this.routeConfigApiService.getAllShipFromInventoryCategoriesInRouteConfig(fromLocationId, toLocationId);
        return response.data || [];
    }

    public async getAllItemsInRouteConfig(fromLocationId: number, toLocationId: number): Promise<Array<Item>> {
        const response = await this.routeConfigApiService.getAllItemsInRouteConfig(fromLocationId, toLocationId);
        if (response.success) {
            return response.data.map((el) => new Item(el));
        }
        return [];
    }

    public async getAllItemsByToLocation(toLocationId: number): Promise<Array<Item>> {
        const response = await this.routeConfigApiService.getAllItemsInToLocation(toLocationId);
        if (response.success) {
            return response.data.map((el) => new Item(el));
        }
        return [];
    }

    public async updateItemList(routeId: number, items: Array<Item>): Promise<{ success: boolean; itemCount: number }> {
        const itemIds = items.map((item) => item.id);
        const dto = new RouteAddItemDTO({
            itemIds,
            routeId,
        });
        const response = await this.routeConfigApiService.updateItemsInRoute(dto);
        return { success: response.success, itemCount: response.data.itemCount };
    }

    public async deleteShippingRoute(routeId: number): Promise<boolean> {
        const response = await this.routeConfigApiService.deleteShippingRoute(routeId);
        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.routeDeleted'));
        }
        return response.success;
    }

    public async updateRouteDetails(routeConfig: LocationRouteConfig): Promise<boolean> {
        const dto = new RouteConfigDetailsDTO({
            routeId: routeConfig.id,
            preferredTrailerTypeId: routeConfig.preferredTrailerTypeId,
            transitTime: routeConfig.transitTime,
            shipFromInventoryCategoryIds: routeConfig.shipFromInventoryCategories.map((x) => x.id),
            shippingLoadTypeId: routeConfig.shippingLoadTypeId,
            minTrailerPercent: routeConfig.minTrailerPercent,
            maxLTLDayRange: routeConfig.maxLTLDayRange,
        });
        const response = await this.routeConfigApiService.updateRouteDetails(dto);
        if (response.success) {
            this.notification.showSuccess(getTranslation('core.common.routeUpdated'));
        }
        return response.success;
    }

    public async getAllFromLocations(): Promise<{ locations: Array<Location> }> {
        const response = await this.routeConfigApiService.getAllFromLocations();
        return { locations: response.data };
    }

    public async getAllToLocations(): Promise<{ locations: Array<Location> }> {
        const response = await this.routeConfigApiService.getAllToLocations();
        return { locations: response.data };
    }

    public async getAllItemsWithRoute(): Promise<{ items: Array<Item> }> {
        const response = await this.routeConfigApiService.getAllItemsWithRoute();
        return { items: response.data };
    }

    public async getRouteConfigByFromAndToLocation(fromLocationId: number, toLocationId: number): Promise<LocationRouteConfig> {
        const response = await this.routeConfigApiService.getRouteConfigByFromAndToLocation(fromLocationId, toLocationId);

        return response.data;
    }
}
