import { ComputedRef, unref } from 'vue';
import TrackedItem from '@/domain/TrackedItem';
import Transaction from '@/domain/Transaction';
import TagScanHandler from './TagScanHandler';

export default class FloortrakReceivingTagScanHandler extends TagScanHandler {
    private transactionRef: ComputedRef<Transaction>;
    private tagListRef: ComputedRef<Array<TrackedItem>>;
    private action: (tag: TrackedItem) => void;

    constructor(
        transactionComputed: ComputedRef<Transaction>,
        currentTagListComputed: ComputedRef<Array<TrackedItem>>,
        actionComputed:(tag: TrackedItem) => void,
    ) {
        super();
        this.transactionRef = transactionComputed;
        this.tagListRef = currentTagListComputed;
        this.action = actionComputed;
    }

    protected override async fetchTags(): Promise<Array<TrackedItem> | undefined> {
        return this.trackedItemService.searchTrackedItemsByBarcodes(this.scannerInput);
    }

    protected override handleScan(fetchedTags: Array<TrackedItem>): Array<string> {
        const transaction = unref(this.transactionRef);
        const duplicateBarcodes = this.scannerInput.filter((b) => transaction.tagList.includes(b));
        const currentScans = unref(this.tagListRef);

        fetchedTags.forEach((tag) => {
            if (duplicateBarcodes.find((barcode) => barcode === tag.barcode)) {
                // this is JS equivalent of continue keyword in .net
                // process next element in the array
                return;
            }
            if (currentScans.find((trackedItem) => trackedItem.barcode === tag.barcode)) {
                duplicateBarcodes.push(tag.barcode);
                return;
            }
            this.action(tag);
        });

        return duplicateBarcodes;
    }
}
