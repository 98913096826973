import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30e5df79"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loading"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_tag_input = _resolveComponent("tag-input")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_safety_inspection_questionnaire_response = _resolveComponent("safety-inspection-questionnaire-response")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createBlock(_component_b_modal, {
    title: _ctx.inspectionTitle,
    "model-value": _ctx.modelValue,
    centered: "",
    "disable-escape-key": "",
    "hide-header-close": "",
    "hide-footer": "",
    "static-backdrop": "",
    size: "lg"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "close-inspection",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.escape && _ctx.escape(...args)))
      }, [
        _createVNode(_component_faicon, { icon: "times" })
      ]),
      (_ctx.state.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("img", { src: _ctx.spinner }, null, 8, _hoisted_2)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isEquipmentInspection)
        ? (_openBlock(), _createBlock(_component_b_row, {
            key: 1,
            class: "carrier-detail-row"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_dropdown_autocomplete_single_select, {
                    data: _ctx.carriers,
                    disabled: !_ctx.modifyCarrierAndTrailer && !!_ctx.shipment?.id && !!_ctx.shipment?.carrierId,
                    "display-name": "name",
                    "empty-text": _ctx.getTranslation('core.common.noCarriersAvailable'),
                    label: _ctx.getTitleCaseTranslation('core.domain.carrier'),
                    "model-value": _ctx.shipment?.carrierName,
                    "search-by": "name",
                    onOnSelect: _ctx.selectCarrier,
                    onOnClear: _ctx.clearCarrier
                  }, null, 8, ["data", "disabled", "empty-text", "label", "model-value", "onOnSelect", "onOnClear"])
                ]),
                _: 1
              }),
              _createVNode(_component_b_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_form_input, {
                    modelValue: _ctx.shipment.trailer,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.shipment.trailer) = $event)),
                    disabled: !_ctx.modifyCarrierAndTrailer && !!_ctx.shipment?.id && !!_ctx.shipment?.trailer,
                    label: _ctx.getTitleCaseTranslation('core.domain.trailerNumberShort')
                  }, null, 8, ["modelValue", "disabled", "label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_b_row, {
            key: 2,
            class: "equipment-detail-row"
          }, {
            default: _withCtx(() => [
              (!_ctx.state.equipmentSelected)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_tag_input, {
                      modelValue: _ctx.state.equipmentNumber,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.equipmentNumber) = $event)),
                      disabled: _ctx.state.equipmentSelected,
                      autofocus: "",
                      label: _ctx.getTitleCaseTranslation('core.domain.equipmentNumber'),
                      placeholder: _ctx.getTranslation('core.common.scanOrEnterEquipmentNumber'),
                      onScan: _ctx.setEquipmentNumberFromScan
                    }, null, 8, ["modelValue", "disabled", "label", "placeholder", "onScan"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_b_form_input, {
                      modelValue: _ctx.state.equipmentNumber,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.equipmentNumber) = $event)),
                      class: "equipment-scan-disabled",
                      disabled: _ctx.state.equipmentSelected,
                      label: _ctx.getTitleCaseTranslation('core.domain.equipmentNumber'),
                      placeholder: _ctx.getTranslation('core.common.scanOrEnterEquipmentNumber')
                    }, null, 8, ["modelValue", "disabled", "label", "placeholder"]),
                    (_ctx.state.equipmentSelected)
                      ? (_openBlock(), _createBlock(_component_b_button, {
                          key: 0,
                          class: "button-clear",
                          onClick: _ctx.clearSelection
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.clear')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]))
            ]),
            _: 1
          })),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.inspection.responses, (response) => {
        return (_openBlock(), _createBlock(_component_safety_inspection_questionnaire_response, {
          key: response.questionId,
          "inspection-response": response
        }, null, 8, ["inspection-response"]))
      }), 128)),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_b_button, {
          disabled: !_ctx.canReject || _ctx.state.rejecting,
          onClick: _ctx.reject
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.submitRejectionReport')) + " ", 1),
            (_ctx.state.rejecting)
              ? (_openBlock(), _createBlock(_component_b_spinner, {
                  key: 0,
                  small: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]),
        _createVNode(_component_b_button, {
          disabled: !_ctx.canContinue || _ctx.state.continuing,
          onClick: _ctx.confirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.continue')) + " ", 1),
            (_ctx.state.continuing)
              ? (_openBlock(), _createBlock(_component_b_spinner, {
                  key: 0,
                  small: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["title", "model-value"]))
}