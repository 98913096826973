import TrackedItem from '@/domain/TrackedItem';
import TrackedItemApiService from '../api/TrackedItemApiService';
import { useNotification } from '@/composable/useNotifications';
import { getTranslation } from '@/services/TranslationService';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default class TrackedItemService {
    private trackedItemApiService: TrackedItemApiService;

    private notifcation = useNotification();

    constructor() {
        this.trackedItemApiService = new TrackedItemApiService();
    }

    public async searchTrackedItemsByBarcodes(barcodes: string[]): Promise<Array<TrackedItem> | undefined> {
        const { success, data } = await this.trackedItemApiService.searchByBarcodes(barcodes);
        this.dispatchNotFound(barcodes, data);
        return success ? data : undefined;
    }

    public async getTrackedItemsInRouteConfig(barcodes: string[], fromId: number, toId: number): Promise<Array<TrackedItem> | undefined> {
        const { success, data } = await this.trackedItemApiService.getTrackedItemsInRouteConfig(barcodes, fromId, toId);
        this.dispatchNotFound(barcodes, data);
        return success ? data : undefined;
    }

    private dispatchNotFound(scannedBarcodes: Array<string>, results: Array<TrackedItem>) {
        scannedBarcodes.forEach((barcode) => {
            const wasNotFound = !results.find((t) => t.barcode === barcode);
            if (wasNotFound) {
                this.notifcation.showWarning(`${getTranslation('core.validation.errorRetrievingTrackedItemWithBarcode')} ${barcode}`);
            }
        });
    }

    public async updateTrackedItem(trackedItem: TrackedItem): Promise<TrackedItem | undefined> {
        const response = await this.trackedItemApiService.updateTrackedItem(trackedItem);

        if (response.success) {
            this.notifcation.showSuccess(`${getTranslation('core.validation.updatedBarcodeTo')} ${trackedItem.barcode}`);
        }

        return response.data;
    }

    public async getTrackedItemByBarcode(barcode: string): Promise<TrackedItem | undefined> {
        const response = await this.trackedItemApiService.getTrackedItemByBarcode(barcode);

        if (!response.success) {
            this.notifcation.showError(`${getTranslation('core.validation.errorRetrievingTrackedItemWithBarcode')} ${barcode}`);
        }

        return response.data;
    }

    public async createTrackedItem(trackedItem: TrackedItem): Promise<DataAccessResponse<Number>> {
        const dto = trackedItem.toCreateTrackedItemDTO();
        const response = await this.trackedItemApiService.createTrackedItem(dto);

        if (response.success) {
            this.notifcation.showSuccess(`${getTranslation('core.validation.createdBarcode')}: ${trackedItem.barcode}`);
        }

        return response;
    }
}
