import axios, { AxiosResponse } from 'axios';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import ShipmentReservationDTO from '@/dtos/ReservationlessShipmentDTO';
import PlannedReservationDTO from '@/dtos/PlannedReservationDTO';
import Reservation from '@/domain/Reservation';
import PlannedReservation from '@/domain/PlannedReservation';

export default class ReservationApiService {
    public async getReservationsByServiceCenter(serviceCenterId: number, startDate: Date, endDate: Date): Promise<DataAccessResponse<Array<Reservation>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Reservation>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/reservation/schedule/${serviceCenterId}`,
            params: { startDate, endDate },
        });
        return new DataListAccessResponseClass<Reservation>(response, Reservation).response;
    }

    public async getReservationsInTimeslotByServiceCenter(serviceCenterId: number, timeSlot: Date): Promise<DataAccessResponse<Array<Reservation>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Reservation>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/reservation/schedule/${serviceCenterId}/timeslot`,
            params: { timeSlot },
        });
        return new DataListAccessResponseClass<Reservation>(response, Reservation).response;
    }

    public async getReservationlessShipmentsByServiceCenter(serviceCenterId: number, startDate: Date, endDate: Date): Promise<DataAccessResponse<Array<ShipmentReservationDTO>>> {
        const response: AxiosResponse<DataAccessResponse<Array<ShipmentReservationDTO>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/reservation/reservationless-shipments/${serviceCenterId}`,
            params: { startDate, endDate },
        });
        return new DataListAccessResponseClass<ShipmentReservationDTO>(response, ShipmentReservationDTO).response;
    }

    public async getPlannedReservationsByServiceCenterForTimeRange(serviceCenterId: number, date: Date, hourRange: number): Promise<DataAccessResponse<Array<PlannedReservation>>> {
        const response: AxiosResponse<DataAccessResponse<Array<PlannedReservation>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/reservation/planned/${serviceCenterId}/range/${hourRange}`,
            params: { date },
        });
        return new DataListAccessResponseClass<PlannedReservation>(response, PlannedReservation).response;
    }

    public async addShipmentReservation(shipmentId: number, reservationDateTime: Date): Promise<DataAccessResponse<number>> {
        const data = {
            shipmentId,
            reservationDateTime,
        };

        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/reservation/shipment`,
            data,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async addPlannedReservation(plannedReservation: PlannedReservationDTO): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/reservation/planned`,
            data: plannedReservation,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async associatePlannedReservationWithShipment(plannedReservationId: number, shipmentId: number): Promise<DataAccessResponse<number>> {
        const data = {
            plannedReservationId,
            shipmentId,
        };

        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/reservation/associate-planned-reservation-with-shipment`,
            data,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async flagShipmentReservationAsLate(shipmentId: number, lateReason: string): Promise<DataAccessResponse<number>> {
        const data = {
            lateReason,
        };

        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/reservation/shipment/${shipmentId}/late`,
            data,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async markShipmentReservationOnTime(shipmentId: number): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/reservation/shipment/${shipmentId}/on-time`,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async markShipmentReservationTrailerArrived(shipmentId: number, trailerNumber: string): Promise<DataAccessResponse<number>> {
        const data = {
            trailerNumber,
        };

        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/reservation/shipment/${shipmentId}/trailer-arrived`,
            data,
        });
        return new DataAccessResponseClass<number>(response).response;
    }
}
