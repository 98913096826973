import Location from '@/domain/Location';
import Carrier from '@/domain/Carrier';
import Dock from '@/domain/Dock';
import TrailerType from '@/domain/TrailerType';
import Shipment from '@/domain/Shipment';
import { normalizeDate, normalizeDateIfMinimum, normalizeDateIfNull } from '@/functions/date';

export default class ShipmentDetails {
    public id?: number = 0;

    public name?: string;

    public carrierId?: number;

    public holdLocation?: Location;

    public carrier?: Carrier;

    public driver?: string;

    public trailerType?: TrailerType;

    public trailer: string = '';

    public seal?: string;

    public freightOrder?: string;

    public carrierReferenceNumber: string = '';

    public licensePlateNumber?: string;

    public carrierRoute?: string;

    public deliveryNotes?: string;

    public dock?: Dock;

    public fromLocation: Location = new Location(); // in db not saved on shipment, on save it is injected into each transaction

    public workStartTime?: Date = new Date(); // datetime

    public workEndTime?: Date; // datetime

    public dockDepartTime!: Date; // datetime

    public dockArrivalTime?: Date; // datetime

    public scheduledDockTimeSlot?: Date;

    public timestamp?: string;

    public lot?: string;

    constructor(init?: ShipmentDetails | Partial<Shipment>) {
        if (init) {
            this.id = init.id;
            this.name = init.name;
            this.carrierId = init.carrierId;
            this.holdLocation = init.holdLocation;
            this.driver = init.driver;
            this.seal = init.seal;
            this.freightOrder = init.freightOrder;
            this.carrierRoute = init.carrierRoute;
            this.deliveryNotes = init.deliveryNotes;
            this.workStartTime = init.workStartTime;
            this.workEndTime = init.workEndTime;
            this.dockArrivalTime = init.dockArrivalTime;
            this.timestamp = init.timestamp;
            this.lot = init.lot;
            if (init.scheduledDockTimeSlot) this.scheduledDockTimeSlot = new Date(init.scheduledDockTimeSlot);
        }

        if (init?.carrierReferenceNumber) {
            this.carrierReferenceNumber = init.carrierReferenceNumber;
        }

        if (init?.licensePlateNumber) {
            this.licensePlateNumber = init.licensePlateNumber;
        }

        if (init?.trailerType) {
            this.trailerType = init.trailerType;
        }

        if (init?.trailer) {
            this.trailer = init.trailer;
        }

        if (init?.fromLocation) {
            this.fromLocation = init.fromLocation;
        }

        if (init?.carrier) {
            this.carrier = init.carrier;
            this.carrierId = this.carrier.id;
        }

        if (init?.dock) {
            this.dock = new Dock(init.dock);
        }
    }

    get shipDate(): Date | null {
        const normalizedDate = normalizeDate(this.dockDepartTime);
        return normalizeDateIfMinimum(normalizedDate);
    }

    set shipDate(newDate: Date | null) {
        this.dockDepartTime = normalizeDateIfNull(newDate);
    }

    get arrivalDate(): Date | null {
        const normalizedDate = normalizeDate(this.dockArrivalTime);
        return normalizeDateIfMinimum(normalizedDate);
    }

    set arrivalDate(newDate: Date | null) {
        this.dockArrivalTime = normalizeDateIfNull(newDate);
    }

    get dockId(): number {
        return this.dock?.id || 0;
    }
}
